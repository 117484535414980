import * as React from 'react';
import clsx from 'clsx';
import { DEFAULT_SIZE, DEFAULT_BG_COLOR } from '../constants';
import { getFallbackString, getFallbackStringSize } from '../utils';
import type { CommonProps } from '../types';
import styles from './style.module.css';

type ImageLoadingStatus = 'idle' | 'loading' | 'loaded' | 'error';

/**
 * Avatar 컴포넌트
 * @returns
 */
export function SquirecleAvatar({
  src,
  alt,
  size = DEFAULT_SIZE,
  backgroundColor = DEFAULT_BG_COLOR,
  className,
}: CommonProps) {
  // 이미지 로딩 상태 체크
  const imageLoadingStatus = useImageLoadingStatus(src);
  // 대체 텍스트를 가운데 위치시키기 위한 보정값
  const altTextPosFixPx = '18';

  return (
    <div
      className={clsx(styles.root, className)}
      style={{ width: `${size}px`, height: `${size}px` }}
      title={alt}
    >
      <svg
        className={styles.image}
        viewBox="0 0 88 88"
        preserveAspectRatio="xMidYMid meet"
      >
        <defs>
          <path
            id="shapeSquircle"
            d="M44,0 C76.0948147,0 88,11.9051853 88,44 C88,76.0948147 76.0948147,88 44,88 C11.9051853,88 0,76.0948147 0,44 C0,11.9051853 11.9051853,0 44,0 Z"
            strokeLinejoin="round"
          />
          <clipPath id="clipSquircle">
            <use xlinkHref="#shapeSquircle" />
          </clipPath>
        </defs>

        <rect
          className={styles.background}
          data-color="10"
          clipPath="url(#clipSquircle)"
          x="0"
          y="0"
          width="88"
          height="88"
          fill={backgroundColor}
        />

        {imageLoadingStatus === 'loaded' && (
          <image
            width="100%"
            height="100%"
            clipPath="url(#clipSquircle)"
            preserveAspectRatio="xMidYMid slice"
            xlinkHref={src}
          />
        )}

        {imageLoadingStatus !== 'loaded' && (
          <text
            className={styles.fallback}
            x="50%"
            y="50%"
            dy={altTextPosFixPx}
            textAnchor="middle"
            style={{ fontSize: getFallbackStringSize(size, 'squirecle') }}
          >
            {getFallbackString(alt)}
          </text>
        )}
      </svg>
    </div>
  );
}

// from @radix/react-avatar
function useImageLoadingStatus(src?: string) {
  const [loadingStatus, setLoadingStatus] =
    React.useState<ImageLoadingStatus>('idle');

  React.useLayoutEffect(() => {
    if (!src) {
      setLoadingStatus('error');
      return;
    }

    let isMounted = true;
    const image = new window.Image();

    const updateStatus = (status: ImageLoadingStatus) => () => {
      if (!isMounted) return;
      setLoadingStatus(status);
    };

    setLoadingStatus('loading');
    image.onload = updateStatus('loaded');
    image.onerror = updateStatus('error');
    image.src = src;

    return () => {
      isMounted = false;
    };
  }, [src]);

  return loadingStatus;
}

export default SquirecleAvatar;
