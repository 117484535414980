import * as React from 'react';
import clsx from 'clsx';
import * as DialogPrimitives from '@radix-ui/react-dialog';

export interface Props extends DialogPrimitives.DialogContentProps {
  isPortal?: boolean;
  /** @property (사전 정의된) 다이얼로그 크기 */
  size?: 'resizeable' | 'full' | 'large' | 'medium' | 'small' | 'xs';
  /** @property 다이얼로그 높이를 컨텐츠에 맞추는 여부 설정 */
  autoHeight?: boolean;
  /** @property 임시 값 (뒷 배경 dim 처리를 위함) */
  dim?: boolean;
  /** @property 오버레이 클릭 시 자동으로 닫히는 기능 차단 여부 default=true */
  useAutoClose?: boolean;
}

export function Content({
  children,
  isPortal = true,
  size,
  autoHeight = false,
  dim = false,
  useAutoClose = true,
  className,
  ...contentProps
}: React.PropsWithChildren<Props>) {
  const Container = isPortal ? DialogPrimitives.Portal : React.Fragment;

  return (
    <Container>
      <DialogPrimitives.Overlay className={clsx({ ['dim open']: dim })} />
      <DialogPrimitives.Content
        className={clsx(
          'dop_dialog', // 기존 'dop_dialog open' => 'dop_dialog' 변경 (openState에 따른 변화 있음, 없는 경우를 대비 기본값으로 open을 넣음)
          {
            dialog_resizable: size === 'resizeable',
            dialog_full: size === 'full',
            dialog_large: size === 'large',
            dialog_medium: size === 'medium',
            dialog_small: size === 'small',
            dialog_xs: size === 'xs',
            auto_height: size === 'full' && autoHeight === true,
          },
          className,
        )}
        {...contentProps}
        onInteractOutside={(e) => {
          if (!useAutoClose) e.preventDefault();
        }}
      >
        {children}
      </DialogPrimitives.Content>
    </Container>
  );
}

export default Content;
