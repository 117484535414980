import { clsx } from 'clsx';
import * as React from 'react';
import '@daouoffice/design/dist/foundation/switch.css';

export interface Props {
  className?: string;
  /** @property ID */
  id: string;
  /** @property input name */
  name?: string;
  /** @property 초기 체크 상태 여부 지정 */
  defaultChecked?: boolean;
  /** @property 활성화 여부 지정 */
  disabled?: boolean;
  /** @property 라벨 */
  label?: string | React.ReactElement;
  /** @property 변경 이벤트 핸들러 */
  onChange?: (checked: boolean) => void;
}

export function Switch({
  className,
  id,
  name,
  label,
  defaultChecked = false,
  disabled = false,
  onChange = () => undefined,
}: Props) {
  const [checkState, setCheckState] = React.useState(defaultChecked);
  const changeHandler = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    onChange(target.checked);
    setCheckState(target.checked);
  };

  return (
    <div
      className={clsx('dop_switch', className, {
        with_label: Boolean(label),
      })}
    >
      <div className="toggle">
        <input
          type="checkbox"
          id={id}
          name={name ? name : id}
          checked={checkState}
          onChange={changeHandler}
          disabled={disabled}
          aria-disabled={disabled}
        />
        {label && <label htmlFor={id}>{label}</label>}
      </div>
    </div>
  );
}

export default Switch;
