import { clsx } from 'clsx';
import type { PropsWithChildren } from 'react';
import * as ButtonPrimitives from './primitives';

import { ColorSetTypes, ShapeTypes, SizeTypes, StyleTypes } from './types';

// ButtonPrimitives의 주요 타입을 re-export
export type TagTypes = ButtonPrimitives.TagTypes;

/**
 * Button 컴포넌트 속성
 */
export interface Props extends ButtonPrimitives.RootProps {
  /** (미리 정해진) 버튼 색 테마 (기본값: 'none') */
  colorset?: ColorSetTypes;
  /** 버튼 형태 (기본값: sqaure) */
  shape?: ShapeTypes;
  /** (미리 정의된) 버튼 크기 */
  size?: SizeTypes;
  /** 버튼 타입 */
  styleType?: StyleTypes;
  /** 태그 타입 */
  tagType?: TagTypes;
  /** 라벨 클래스 네임 */
  labelClassName?: string;
  /** 라벨과 함께 쓸 것인지 여부 (기본 값 True) */
  withTitle?: boolean;
}

/**
 * Button 컴포넌트
 * @returns
 */
export function Button({
  children,
  shape = 'round',
  size,
  colorset = 'major',
  styleType: buttonType = 'solid',
  tagType = 'button',
  className,
  labelClassName = 'txt',
  disabled = false,
  withTitle = true,
  ...props
}: PropsWithChildren<Props>) {
  return (
    <ButtonPrimitives.Root
      as={tagType}
      className={clsx(
        'select-none',
        shape,
        buttonType,
        {
          btn_small: size === 'small',
          btn_medium: size === 'medium',
          btn_large: size === 'large',
        },
        colorset,
        className,
      )}
      disabled={disabled}
      {...props}
    >
      {children}
      {withTitle && <ButtonPrimitives.Label className={labelClassName} />}
    </ButtonPrimitives.Root>
  );
}

export default Button;
